import * as React from "react";
import { shallowEqual } from "react-redux";
import { Routes as RouterRoutes, Route, useLocation, Navigate } from "react-router-dom";

import { LoaderContainer } from "./components/Utilities/LoaderContainer";
import { lazyWithRetry } from "./hooks/lazyWithRetry";
import { querystring } from "./libs/utilsLib";
import { useAppSelector } from "./redux/store";

const { Suspense, } = React;

const Home = lazyWithRetry(() => import("./containers/Home/Home"));
const Login = lazyWithRetry(() => import("./containers/Login"));
const Patient = lazyWithRetry(() => import("./containers/Patient"));
const NotFound = lazyWithRetry(() => import("./containers/NotFound"));
const Providers = lazyWithRetry(() => import("./containers/Providers"));
const ForgotPassword = lazyWithRetry(() => import("./containers/ForgotPassword"));
const SamlLogin = lazyWithRetry(() => import("./containers/SamlLogin"));
const Signup = lazyWithRetry(() => import("./containers/Signup"));
const MyAccount = lazyWithRetry(() => import("./containers/MyAccount"));
const Admin = lazyWithRetry(() => import("./containers/Admin"));
const Organization = lazyWithRetry(() => import("./containers/Organization"));
const EditOrganization = lazyWithRetry(() => import("./containers/EditOrganization"));
const Reports = lazyWithRetry(() => import("./containers/Reports"));
const Kiosk = lazyWithRetry(() => import("./containers/Kiosk/Kiosk"));
const Session = lazyWithRetry(() => import("./containers/Kiosk/Session"));
const Management = lazyWithRetry(() => import("./containers/Kiosk/Management"));
const MobileDeviceNotFound = lazyWithRetry(() => import("./containers/Kiosk/MobileDeviceNotFound"));

interface RoutesProps {
  appProps: {
    handleLogout: () => void;
    logoutIntervalMinutes: React.MutableRefObject<number>;
    samlError: string;
  };
};

export const Routes: React.FC<RoutesProps> = ({ appProps }) => {
  const location = useLocation();
  const redirectTo = `/login?redirect=${location.pathname}${location.search}`;
  const { isKioskMode, } = useAppSelector(state => state.kiosk);
  const provider = useAppSelector(state => state.provider, shallowEqual);
  const isAuthenticated = !!provider.providerID;
  const isOrgAdmin = !!provider.viewingAs?.orgAdmin || !!provider.fullAdmin;
  const redirect = querystring("redirect");
  const unauthenticatedRedirectTo = redirect === "" || redirect === null ? `/${isKioskMode ? 'kiosk' : ''}` : redirect;
  const redirectElement = <Navigate replace to={redirectTo} />;
  const notOrgAdminRedirectTo = <Navigate replace to="/" />;
  const unauthenticatedRedirectElement = <Navigate replace to={unauthenticatedRedirectTo} />;

  return (
    <Suspense fallback={<LoaderContainer />}>
      <RouterRoutes>
        {/******** Unauthenticated Routes ********/}
        <Route path="/login" element={!isAuthenticated ? <Login {...appProps} /> : unauthenticatedRedirectElement} />
        <Route path="/saml/:email" element={!isAuthenticated ? <SamlLogin {...appProps} /> : unauthenticatedRedirectElement} />
        <Route path="/saml" element={!isAuthenticated ? <SamlLogin {...appProps} /> : unauthenticatedRedirectElement} />
        <Route path="/forgot-password" element={!isAuthenticated ? <ForgotPassword {...appProps} /> : unauthenticatedRedirectElement} />
        <Route path="/signup/:email/:code" element={!isAuthenticated ? <Signup {...appProps} /> : unauthenticatedRedirectElement} />

        {/******** Authenticated Routes ********/}
        {isKioskMode
          ? (<>
            <Route path="/" element={isAuthenticated ? <Kiosk {...appProps} /> : redirectElement} />
            <Route path="/mobile-not-found" element={isAuthenticated ? <MobileDeviceNotFound {...appProps} /> : redirectElement} />
            <Route path="/kiosk/:patientID/:kioskSessionID" element={isAuthenticated ? <Session {...appProps} /> : redirectElement} />
            <Route path="/kiosk" element={isAuthenticated ? <Kiosk {...appProps} /> : redirectElement} />
            <Route path="/kiosk/manage" element={isAuthenticated ? <Management {...appProps} /> : notOrgAdminRedirectTo} />
            <Route path="/kiosk/account" element={isAuthenticated ? <MyAccount {...appProps} /> : redirectElement} />
          </>)
          : (<>
            <Route path="/" element={isAuthenticated ? <Home {...appProps} /> : redirectElement} />
            <Route path="/patient/:id" element={isAuthenticated ? <Patient {...appProps} /> : redirectElement} />
            <Route path="/patient/:id/:pftType/:seriesID" element={isAuthenticated ? <Patient {...appProps} /> : redirectElement} />
            <Route path="/organization" element={isAuthenticated ? <Organization {...appProps} /> : redirectElement} />
            <Route path="/organization/:organizationID" element={isAuthenticated ? <EditOrganization {...appProps} /> : redirectElement} />
            <Route path="/reports" element={isAuthenticated ? <Reports {...appProps} /> : redirectElement} />
            <Route path="/providers" element={isAuthenticated && isOrgAdmin ? <Providers {...appProps} /> : notOrgAdminRedirectTo} />
            <Route path="/admin" element={isAuthenticated && isOrgAdmin ? <Admin {...appProps} /> : notOrgAdminRedirectTo} />
            <Route path="/account" element={isAuthenticated ? <MyAccount {...appProps} /> : redirectElement} />
          </>)}

        <Route element={<NotFound />} />
      </RouterRoutes>
    </Suspense>
  );
};