import { Reducer } from "redux";
import { Kiosk, MobileDevice, PatientForKiosk, Session } from "../../Types/Common/KioskTypes";
import { OrganizationType } from "../../Types/Common/Types";
import { ADD_PATIENT_TO_KIOSK, CLEAR_KIOSK_DATA, CLEAR_KIOSK_STORE, CLEAR_SELECTED_PATIENT, EDIT_PATIENT_FOR_KIOSK, SET_CONFIG, SET_IN_SESSION, SET_IN_ECOA, SET_KIOSK, SET_KIOSK_MODE, SET_MOBILE_DEVICE, SET_PATIENTS_FOR_KIOSK, SET_SELECTED_ORGANIZATION, SET_SELECTED_PATIENT, SET_SESSIONS, SET_SPIROMETER_CONNECTED, SET_RESET_BLUETOOTH_COMPLETED } from "./actionTypes";

const INITIAL_STATE = {
  kiosk: null as Kiosk | null,
  patients: null as Record<string, PatientForKiosk> | null,
  sessions: null as Session[] | null,
  isKioskMode: false,
  isInSession: false,
  isInEcoa: false,
  selectedPatient: undefined as PatientForKiosk | undefined,
  selectedOrganization: undefined as OrganizationType | undefined,
  mobileDevice: undefined as MobileDevice | undefined,
  isSpirometerConnected: false,
  resetBluetoothCompleted: false
};

interface ActionType {
  type: string;
  payload: Kiosk | PatientForKiosk[] | Session[] | PatientForKiosk | OrganizationType | MobileDevice | boolean
};

export const kioskReducer: Reducer<typeof INITIAL_STATE, ActionType> = (state = INITIAL_STATE, action: ActionType) => {
  switch (action.type) {
    case SET_PATIENTS_FOR_KIOSK:
      if (typeof action.payload !== 'object') return state;
      return { ...state, patients: action.payload };

    case EDIT_PATIENT_FOR_KIOSK:
      if (!(typeof action.payload === 'object') || !('patientID' in (action.payload)) || !action.payload.patientID) return state;
      const patient = state.patients ? state.patients[action.payload.patientID] : {};
      const patients = { ...state.patients, [action.payload.patientID]: { ...patient, ...action.payload } };
      return { ...state, patients };

    case ADD_PATIENT_TO_KIOSK:
      if (typeof action.payload !== 'object' || !('patientID' in action.payload) || !action.payload.patientID) return state;
      const newPatients = { ...state.patients, [action.payload.patientID]: action.payload }
      return { ...state, patients: newPatients };

    case SET_SESSIONS:
      if (!Array.isArray(action.payload)) return state;
      return { ...state, sessions: action.payload };

    case SET_KIOSK:
      if (typeof action.payload === 'boolean' || !('kioskID' in action.payload) || !action.payload.kioskID) return state;
      return { ...state, kiosk: action.payload };

    case SET_CONFIG:
      if (typeof action.payload === 'boolean' || !('ShowSvc' in action.payload || 'ShowFvc' in action.payload) || (!action.payload.ShowFvc && !action.payload.ShowSvc)) return state;
      return { ...state, config: action.payload };

    case SET_KIOSK_MODE:
      if (typeof action.payload !== 'boolean') return state;
      return { ...state, isKioskMode: action.payload };

    case SET_IN_SESSION:
      if (typeof action.payload !== 'boolean') return state;
      return { ...state, isInSession: action.payload };

    case SET_IN_ECOA:
      if (typeof action.payload !== 'boolean') return state;
      return { ...state, isInEcoa: action.payload };

    case SET_SELECTED_ORGANIZATION:
      return { ...state, selectedOrganization: action.payload };

    case SET_SELECTED_PATIENT:
      return { ...state, selectedPatient: action.payload };

    case SET_MOBILE_DEVICE:
      return { ...state, mobileDevice: action.payload };

    case SET_SPIROMETER_CONNECTED:
      return { ...state, isSpirometerConnected: action.payload };

    case SET_RESET_BLUETOOTH_COMPLETED:
      return { ...state, resetBluetoothCompleted: action.payload };

    case CLEAR_SELECTED_PATIENT:
      return { ...state, selectedPatient: undefined };

    case CLEAR_KIOSK_DATA:
      return { ...state, kiosk: null, patients: null, sessions: null };

    case CLEAR_KIOSK_STORE:
      return INITIAL_STATE;

    default:
      return state;
  };
};